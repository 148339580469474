import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import CreateIcon from "@mui/icons-material/Create";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPass,
  changeUserworls,
  getUsers,
  removeUsers,
} from "../../store/superadmin/usersSlice";
import { Link } from "react-router-dom";
import TablePaginaiton from "../../components/TablePaginaiton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import { getDhUsers, getPmUsers } from "../../store/usersLists";
import NewPassDialog from "../../components/NewPassDialog";

function ListUsers() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.usersSlice.users);
  const totlepages = useSelector((state) => state.usersSlice.pagesnumber);
  const pmusers = useSelector((state) => state.usersListSlice.pmUsers);
  const dhusers = useSelector((state) => state.dhUsersReducer.dhUsers);
  // const isLoading = useSelector((state) => state.usersSlice.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const newpassword = useSelector((state) => state.usersSlice.newpassword);
  const [loading, setLoading] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [pmId, setPmId] = useState("");
  const [dhId, setDhId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [page, setPage] = useState(1);
  const selectionRows = false;
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "4") {
    roleName = "tm";
  }

  useEffect(() => {
    dispatch(getUsers({ pageNumber: 0, filterRolId: "", pmId: "", dhID: "" }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
    dispatch(getPmUsers())
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      });
    dispatch(getDhUsers())
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      });
  }, [dispatch]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);

    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(removeUsers(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const handlepageChange = (event, value) => {
    setPage(value);
    dispatch(getUsers(value));
  };

  const changePass = (userId) => {
    // console.log(userId);
    setLoading(true);
    dispatch(changeUserPass(userId)).then((data) => {
      // console.log("data");
      setLoading(false);
      setOpenDialog(true);
    });
  };

  const coulmns = [
    { field: "id", headerName: "رقم التعريف" },
    { field: "name", headerName: "الاسم", editable: true },
    {
      field: "email",
      headerName: "البريد الإلكتروني",
      editable: true,
      width: 200,
    },
    { field: "role_name", headerName: "المنصب", editable: true },
    { field: "national_id", headerName: "الرقم الوطني", editable: true },
    { field: "employee_id", headerName: "رقم الموظف", editable: true },
    { field: "phone_num", headerName: "رقم الهاتف", editable: true },
    { field: "address", headerName: "العنوان", editable: true },
    { field: "birthdate", headerName: "تاريخ الميلاد", editable: true },
    { field: "license_type", headerName: "نوع الترخيص", editable: true },
    {
      field: "actions",
      headerName: "اختيارات",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="change-pass">
            <LockResetIcon
              onClick={() => changePass(params.row.id)}
            ></LockResetIcon>
          </span>
          <span className="editicon">
            <Link
              to={`/superadmin/edit-user/${params.row.role_name}/${params.row.id}`}
            >
              <CreateIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>

          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <>
        <NewPassDialog
          newpassword={newpassword}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
        <div className="manage_employee">
          <div className="add_button">
            <Link to={`/${roleName}/add-new-user/pm`}>اضافة مدير مشروع</Link>
            <Link to={`/${roleName}/add-new-user/dh`}>اضافة رئيس قسم</Link>
            <Link to={`/${roleName}/add-new-user/monitor`}>اضافة متابع </Link>
            <Link to={`/${roleName}/add-new-team`}>اضافة فريق</Link>
            <Link to={`/${roleName}/add-new-user/tm`}>اضافة عضو فريق</Link>
          </div>

          <Grid container spacing={8} className="algin-center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={9}
              className="order2intablet"
            >
              <div className="table_show table_style">
                <h5>قائمة بجميع الموظفين</h5>

                <div className="filter">
                  <h5>تصفيه</h5>
                  <form action="">
                    <div className="form-group">
                      <select
                        name=""
                        value={pmId}
                        onChange={(e) => {
                          setPmId(e.target.value);
                        }}
                        id=""
                        className="form-control"
                      >
                        <option value="" selected>
                          {" "}
                          اختر مدير المشروع{" "}
                        </option>
                        {pmusers.map((pm) => (
                          <option value={pm.id} key={pm.id}>
                            {" "}
                            {pm.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        name=""
                        value={dhId}
                        onChange={(e) => {
                          setDhId(e.target.value);
                        }}
                        id=""
                        className="form-control"
                      >
                        <option value="" selected>
                          {" "}
                          اختر رئيس القسم{" "}
                        </option>
                        {dhusers.map((dh) => (
                          <option value={dh.id} key={dh.id}>
                            {" "}
                            {dh.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        name=""
                        value={teamId}
                        onChange={(e) => {
                          setTeamId(e.target.value);
                        }}
                        id=""
                        className="form-control"
                      >
                        <option value="" selected>
                          {" "}
                          اختر المنصب{" "}
                        </option>
                        <option value="4"> مشرف </option>
                        <option value="5"> فني </option>
                      </select>
                    </div>

                    <div className="btn_submit">
                      <button
                        className="btn"
                        onClick={() => {
                          setLoading(true);
                          dispatch(
                            getUsers({
                              pageNumber: 0,
                              filterRolId: teamId,
                              pmId: pmId,
                              dhID: dhId,
                            })
                          )
                            .unwrap()
                            .then((data) => {
                              setLoading(false);
                            });
                        }}
                      >
                        بحث
                      </button>
                    </div>
                  </form>
                </div>

                <button
                  onClick={() => {
                    setLoading(true);
                    dispatch(
                      getUsers({
                        pageNumber: 0,
                        filterRolId: "",
                        pmId: "",
                        dhID: "",
                      })
                    )
                      .unwrap()
                      .then((data) => {
                        setLoading(false);
                      });
                  }}
                  className="showall"
                >
                  عرض الكل
                </button>
                <DataGridDemo
                  coulmns={coulmns}
                  rows={users}
                  selectionRows={selectionRows}
                />

                {/* <TablePaginaiton
                totlepages={totlepages}
                page={page}
                setPage={setPage}
                handlepageChange={handlepageChange}
              /> */}
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={3}
              className="order1intablet nopaadingmobile"
            >
              <div className="options_buttons">
                <ul className="list-unstyled">
                  <li>
                    <button
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          getUsers({
                            pageNumber: 0,
                            filterRolId: "3",
                            pmId: "",
                            dhID: "",
                          })
                        )
                          .unwrap()
                          .then((data) => {
                            setLoading(false);
                          });
                      }}
                    >
                      قائمة رؤساء الاقسام
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          getUsers({
                            pageNumber: 0,
                            filterRolId: "2",
                            pmId: "",
                            dhID: "",
                          })
                        )
                          .unwrap()
                          .then((data) => {
                            setLoading(false);
                          });
                      }}
                    >
                      قائمة مديرين المشاريع
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          getUsers({
                            pageNumber: 0,
                            filterRolId: "6",
                            pmId: "",
                            dhID: "",
                          })
                        )
                          .unwrap()
                          .then((data) => {
                            setLoading(false);
                          });
                      }}
                    >
                      قائمة المتابعين
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          getUsers({
                            pageNumber: 0,
                            filterRolId: "4",
                            pmId: "",
                            dhID: "",
                          })
                        )
                          .unwrap()
                          .then((data) => {
                            setLoading(false);
                          });
                      }}
                    >
                      قائمة المشرفين
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          getUsers({
                            pageNumber: 0,
                            filterRolId: "5",
                            pmId: "",
                            dhID: "",
                          })
                        )
                          .unwrap()
                          .then((data) => {
                            setLoading(false);
                          });
                      }}
                    >
                      قائمة الفنيين
                    </button>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default ListUsers;
