import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");

export const getProfile = createAsyncThunk(
  "profile/fetshuserprofile",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/profile`, {
        headers,
      });
      const data = await res.data.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "profile/feditprofile",
  async (profileData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(`${endpoint}/api/profile`, profileData, {
        headers,
      });
      const data = await res.data.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "profile/resetpassword",
  async (passData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(`${endpoint}/api/password/reset`, passData, {
        headers,
      });
      const data = await res.data.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userProfileSlice = createSlice({
  name: "profile",
  initialState: { isLoading: true, usersprofile: {} },
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getProfile.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.usersprofile = action.payload;
    },
    [getProfile.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editProfile
    [editProfile.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editProfile.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [editProfile.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // resetPassword
    [resetPassword.pending]: (state, action) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default userProfileSlice.reducer;
