import React from "react";
import { Outlet } from "react-router-dom";
import Topheader from "../../components/Topheader";
import AdSideNav from "../../components/AdSideNav";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "../../App";
import NotAuthPage from "../NotAuthPage";
import MobileIcon from "../../components/MobileIcon";
import BackDrop from "../../components/BackDrop";

function SuperAdmin() {
  const role_id = localStorage.getItem("role_id");
  const token = localStorage.getItem("token");
  if (role_id == "" || token == "") return <App />;
  if (role_id !== "1") return <NotAuthPage />;


  const openSideMenue = () =>{
    // console.log("openSideMenue");
    document.querySelector('.side-nav').classList.add('openmenu');
    document.querySelector(".backdropmenu").classList.add("backdropmenu_active");
  }


  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <BackDrop />
      <Topheader />
      <div className="main_content">
        <MobileIcon openSideMenue={openSideMenue} />
        <AdSideNav />
        <div className="main_content_wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin;
