import React from "react";

function NotificationComp({ notifications }) {
  return (
    <>
      <div className="notification_component">
        <h5>الاشعارات</h5>
        <ul className="norilist">
          {notifications.map((noti) => (
            <li key={noti.id}>
              <p>{noti.content}</p>
              <span className="data">{noti?.timestamp?.split(" ")[0]}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default NotificationComp;
