import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";


export const sendAuth = createAsyncThunk(
  "auth/fetshauth",
  async (authData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}/api/login`, authData);
      const data = await res.data.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: true,
    token: "",
    role_id: "",
  },
  reducers: {},
  extraReducers: {
    [sendAuth.pending]: (state, action) => {
      state.isLoading = true;
    },
    [sendAuth.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.token = action.payload.token;
      state.role_id = action.payload.role_id;
    },
    [sendAuth.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default authSlice.reducer;
