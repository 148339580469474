import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
}

export const getTeamByDh = createAsyncThunk(
  "teambydh/fetsteambydh",
  async (dhID, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/team/${dhID}`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTallDhTeams = createAsyncThunk(
  "teambydh/fetsalldhteam",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/team`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTallDhTeams2 = createAsyncThunk(
  "teambydh/fetsalldhteam2",
  async (pageNumber, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/team?page=${pageNumber}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignDistrictToTeam = createAsyncThunk(
  "teambydh/fetstasigndistricttoteam",
  async (districtData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/district/assign`,
        districtData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSuperVisorByDh = createAsyncThunk(
  "teambydh/fetsteasupervisorbyhd",
  async (dhID, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/user?role_id=4&dh_id=${dhID}&page=0`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const gettechnicalByDh = createAsyncThunk(
  "teambydh/fetsteatechnicalbyhd",
  async (dhID, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/user?role_id=5&dh_id=${dhID}&page=0`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTeam = createAsyncThunk(
  "teambydh/fetsteaddnewteam",
  async (teamData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/team`,
        teamData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getunassignedsupervisors = createAsyncThunk(
  "teambydh/fetsunassignedsupervisors",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user/list`, {
        headers,
      });
      const data = await res.data.data.supervisors.unassigned;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getunassignedtechnicals = createAsyncThunk(
  "teambydh/fetsunassignedtechnicals",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user/list`, {
        headers,
      });
      const data = await res.data.data.technicians.unassigned;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeamById = createAsyncThunk(
  "teambydh/fetsteambyid",
  async (teamId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/team/by-id/${teamId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTeam = createAsyncThunk(
  "teambydh/fetsteeditnewteam",
  async (teamData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${endpoint}/api/${roleName}/team/${teamData.id}`,
        teamData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getunassignedsupervisorsbydh = createAsyncThunk(
  "teambydh/fetsunassignedsupervisorsbydh",
  async (dhId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user/by-dh/${dhId}`, {
        headers,
      });
      const data = await res.data.data.supervisors.unassigned;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getunassignedtechnicalsbydh = createAsyncThunk(
  "teambydh/fetsunassignedtechnicalsbydh",
  async (dhId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user/by-dh/${dhId}`, {
        headers,
      });
      const data = await res.data.data.technicians.unassigned;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removetmUsers = createAsyncThunk(
  "teambydh/removetmusers",
  async (userid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(`${endpoint}/api/${roleName}/user/${userid}`, {
        headers,
      });
      const data = await res.data.data;
      return userid;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);


export const removeteam = createAsyncThunk(
  "teambydh/removeteam",
  async (userid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(`${endpoint}/api/${roleName}/team/${userid}`, {
        headers,
      });
      const data = await res.data.data;
      return userid;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);


const teamSlice = createSlice({
  name: "teambydh",
  initialState: {
    isLoading: true,
    teamByDh: [],
    supervisors: [],
    technicals: [],
    allDhTeam: [],
    allDhTeam2: [],
    pagesnumber: "",
    unassignedsupervisors: [],
    unassignedtechnicals: [],
    unassignedsupervisorsbydh:[],
    unassignedtechnicalsbydh: [],
    singleTeam: {},
  },
  reducers: {},
  extraReducers: {
    [getTeamByDh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTeamByDh.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.teamByDh = action.payload;
    },
    [getTeamByDh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getTeamById
    [getTeamById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTeamById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleTeam = action.payload;
    },
    [getTeamById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getunassignedsupervisors
    [getunassignedsupervisors.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getunassignedsupervisors.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unassignedsupervisors = action.payload;
    },
    [getunassignedsupervisors.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getunassignedtechnicals
    [getunassignedtechnicals.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getunassignedtechnicals.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unassignedtechnicals = action.payload;
    },
    [getunassignedtechnicals.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // getunassignedsupervisorsbydh
    [getunassignedsupervisorsbydh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getunassignedsupervisorsbydh.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unassignedsupervisorsbydh = action.payload;
    },
    [getunassignedsupervisorsbydh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getunassignedtechnicalsbydh
    [getunassignedtechnicalsbydh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getunassignedtechnicalsbydh.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unassignedtechnicalsbydh = action.payload;
    },
    [getunassignedtechnicalsbydh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getTallDhTeams
    [getTallDhTeams.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTallDhTeams.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allDhTeam = action.payload;
    },
    [getTallDhTeams.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getTallDhTeams
    [getTallDhTeams2.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTallDhTeams2.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allDhTeam2 = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getTallDhTeams2.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // assignDistrictToTeam
    [assignDistrictToTeam.pending]: (state, action) => {
      state.isLoading = true;
    },
    [assignDistrictToTeam.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.teamByDh = action.payload;
    },
    [assignDistrictToTeam.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getSuperVisorByDh
    [getSuperVisorByDh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSuperVisorByDh.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.supervisors = action.payload;
    },
    [getSuperVisorByDh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // gettechnicalByDh
    [gettechnicalByDh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [gettechnicalByDh.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.technicals = action.payload;
    },
    [gettechnicalByDh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addTeam
    [addTeam.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addTeam.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addTeam.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editTeam
    [editTeam.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editTeam.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editTeam.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // removetmUsers
    [removetmUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removetmUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allDhTeam2 = state.allDhTeam2.filter((user) => user.id !== action.payload);
    },
    [removetmUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // removeteam
    [removeteam.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removeteam.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allDhTeam2 = state.allDhTeam2.filter((user) => user.id !== action.payload);
    },
    [removeteam.rejected]: (state, action) => {
      state.isLoading = false;
    },

  },
});

export default teamSlice.reducer;
