import { Outlet } from "react-router-dom";
import Topheader from "./components/Topheader";
import "./css/style.css";
import Login from "./routes/Login";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <Login />
    </div>
  );
}

export default App;
