import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  Marker,
  MarkerF,
  Polygon,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MapDialog({
  mapPoints,
  openMapoDialog,
  setOpenMapDialog,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenMapDialog(false);
  };


  const isValidCoordinate = (coordinate) => {
    return !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
  };

  const center = {
    lat: isValidCoordinate(mapPoints.lat) ? parseFloat(mapPoints.lat) : 0,
    lng: isValidCoordinate(mapPoints.lng) ? parseFloat(mapPoints.lng) : 0
  };

  const implementingCenter = {
    lat: isValidCoordinate(mapPoints.implementing_lat) ? parseFloat(mapPoints.implementing_lat) : 0,
    lng: isValidCoordinate(mapPoints.implementing_lang) ? parseFloat(mapPoints.implementing_lang) : 0
  };



  // const exampleMapStyles = [
  //   {
  //     featureType: "all",
  //     elementType: "all",

  //   },
  // ];

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  return (
    <div>
      <Dialog
        open={openMapoDialog}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="map_dialog"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"موقع التركيب"}</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-slide-description">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
              options={{
                // styles: exampleMapStyles,
                streetViewControl: false,
              }}
            >
              <Marker
                position={center}
                icon={{
                  url:
                    mapPoints.status === "3"
                      ? "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                      : "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
                // onClick={() => handleMarkerClick(parseFloat(mapPoints.lat))}
              />

              
              <OverlayView
                position={{
                  lat: parseFloat(mapPoints.lat),
                  lng: parseFloat(mapPoints.lng),
                }}
                mapPaneName={OverlayView.OVERLAY_LAYER}
                getPixelPositionOffset={(width, height) => ({
                  x: -width / 2,
                  y: -height - 52,
                })}
                zIndex={10000} // Set a higher zIndex value
              >
                <div
                  style={{
                    background: "white",
                    border: "1px solid #ccc",
                    width: "fit-content",
                    zIndex: "99",
                    padding: "5px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {mapPoints.building_code}
                </div>
              </OverlayView>
            </GoogleMap>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
