import React, { useEffect, useMemo, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import {
  addUsers,
  editUser,
  getUsersById,
} from "../../store/superadmin/usersSlice";
import { getDhUsers, getPmUsers } from "../../store/usersLists";
import { toast } from "react-toastify";
import SkelotonLoading from "../../components/SkelotonLoading";
import Loading from "../../components/Loading";

function EditUser() {
  const params = useParams();
  const location = useLocation();
  const userType = params.type;
  const usert = location.pathname.split("/")[3];
  // console.log(location);
  const navigate = useNavigate();
  const userId = useParams().id;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const pmUsers = useSelector((state) => state.usersListSlice.pmUsers);
  const dhUsers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const isLoading = useSelector((state) => state.usersListSlice.isLoading);
  const singleuser = useSelector((state) => state.usersSlice.singleuser);
  const [open, setOpen] = useState();
  const [loading ,setLoding] = useState();
  const [inputs, setInputs] = useState({
    id: parseInt(userId),
    name: "",
    email: "",
    password: "",
    national_id: "",
    employee_id: "",
    phone_num: "",
    address: "",
    birthdate: "",
    license_id: "",
    license_type: "",
    role_id: "",
    dh_id: "",
    pm_id: "",
    profile_image: "",
    passport_copy: "",
  });

  const localrole_id = localStorage.getItem("role_id");
  var roleName = "";
  if (localrole_id == "1") {
    roleName = "superadmin";
  } else if (localrole_id == "2") {
    roleName = "pm";
  }else if (localrole_id == "3") {
    roleName = "dh";
  }


  useEffect(() => {
    dispatch(getPmUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDhUsers());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getUsersById(userId))
        .unwrap()
        .then((data) => {
          // console.log(data);
          setInputs(data);
        }).catch((error) => {
          toast.error(error.message);
        });
    }
  }, [userId, dispatch]);

  const role_id =
    userType === "pm"
      ? 2
      : userType === "dh"
      ? 3
      : userType === "admin"
      ? 1
      : null;

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handelUploadPicture = (e) => {
    const { name, files } = e.target;

    // Assuming you are updating the state with the selected file
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: files[0], // Update the specific input with the file object
    }));
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoding(true);
    if (simpleValidator.current.allValid()) {
      const formData = new FormData();
      formData.append("profile_image", inputs.profile_image);
      formData.append("passport_copy", inputs.passport_copy);
      Object.keys(inputs).forEach((key) => {
        formData.append(key, inputs[key]);
      });

      // console.log(inputs);
      dispatch(editUser({userdata:formData, userId:inputs.id}))
        .unwrap()
        .then((data) => {
          toast.success("تم التعديل بنجاح");
          setLoding(false);
          navigate(`/${roleName}/all-users`)
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style formEditData">
            <div className="form_header">
              {userType === "pm" ? <h5>إضافة مدير مشروع جديد</h5> : ""}
              {userType === "dh" ? <h5>إضافة رئيس قسم جديد</h5> : ""}
              {userType === "tm" ? <h5>إضافة عضو فريق جديد</h5> : ""}
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form action="" onSubmit={saveData}>
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">صوره شخصيه:</label>
                  <input
                    type="file"
                    name="profile_image"
                    onChange={handelUploadPicture}
                    className="form-control"
                    placeholder="يرجي ادخال اسم المدير"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("profile_image")
                    }
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">نسخة جواز السفر:</label>
                  <input
                    type="file"
                    name="passport_copy"
                    onChange={handelUploadPicture}
                    className="form-control"
                    placeholder="يرجي ادخال اسم المدير"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("passport_copy")
                    }
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">الاسم:</label>
                  <input
                    type="text"
                    name="name"
                    value={inputs.name}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال اسم المدير"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("name")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "name",
                    inputs.name,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">البريد الاكتروني:</label>
                  <input
                    type="text"
                    name="email"
                    value={inputs.email}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال البريد الاكتروني"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("email")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "email",
                    inputs.email,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">الرقم الوطني:</label>
                  <input
                    type="text"
                    name="national_id"
                    value={inputs.national_id}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال الرقم الوطني"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("national_id")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "national_id",
                    inputs.national_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">رقم الموظف:</label>
                  <input
                    type="text"
                    name="employee_id"
                    value={inputs.employee_id}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال رقم الموظف"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("employee_id")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "employee_id",
                    inputs.employee_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">رقم الهاتف:</label>
                  <input
                    type="text"
                    name="phone_num"
                    value={inputs.phone_num}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال رقم الهاتف"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("phone_num")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "phone_num",
                    inputs.phone_num,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">العنوان:</label>
                  <input
                    type="text"
                    name="address"
                    value={inputs.address}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال العنوان "
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("address")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "address",
                    inputs.address,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">تاريخ الميلاد:</label>
                  <input
                    type="date"
                    name="birthdate"
                    value={inputs.birthdate}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال "
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("birthdate")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "birthdate",
                    inputs.birthdate,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">رقم الترخيص:</label>
                  <input
                    type="text"
                    name="license_id"
                    value={inputs.license_id}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال رقم الترخيص"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("license_id")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "license_id",
                    inputs.license_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">نوع الترخيص:</label>
                  <input
                    type="text"
                    name="license_type"
                    value={inputs.license_type}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال نوع الترخيص"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("license_type")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "license_type",
                    inputs.license_type,
                    "required"
                  )}
                </div>
              </div>

              {/* {userType === "pm" ? "" : ""}
              {userType === "dh" ? (
                <div className="form-group">
                  <div className="form-goup-label">
                    <label htmlFor="">اختر مدير المشروع:</label>
                    <select
                      name="pm_id"
                      onChange={handelInputChange}
                      id=""
                      value={inputs.pm_id}
                      className="form-control"
                    >
                      <option value="1" selected disabled>
                        يرجى اختيار المنصب
                      </option>
                      {pmUsers.map((pmus) => (
                        <option key={pmus.id} value={pmus.id}>
                          {" "}
                          {pmus.name}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}

              {userType === "tm" ||
              userType === "supervisor" ||
              userType === "tech" ? (
                <>
                  <div className="form-group">
                    <div className="form-goup-label">
                      <label htmlFor="">اختر المنصب:</label>
                      <select
                        name="role_id"
                        value={inputs.role_id}
                        onChange={handelInputChange}
                        id=""
                        className="form-control"
                      >
                        <option value="0" selected disabled>
                          يرجى اختيار المنصب
                        </option>
                        <option value="4">مشرف</option>
                        <option value="5">فني</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-goup-label">
                      <label htmlFor="">اختر رئيس القسم:</label>
                      <select
                        name="dh_id"
                        value={inputs.dh_id}
                        onChange={handelInputChange}
                        id=""
                        className="form-control"
                      >
                        <option value="0" selected disabled>
                          يرجى اختيار المنصب
                        </option>
                        {dhUsers.map((dhus) => (
                          <option key={dhus.id} value={dhus.id}>
                            {" "}
                            {dhus.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )} */}

              <div className="btn_submit">
                <button className="btn_style">اضافة</button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default EditUser;
