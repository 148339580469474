import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import CircularChart from "../../components/CircularChart";
import { useDispatch, useSelector } from "react-redux";
import { getStatics } from "../../store/staticsSlice";
import Loading from "../../components/Loading";
import BarChart from "../../components/BarChart";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CircularChartBuilding from "../../components/CircularChartBuilding";
import { toast } from "react-toastify";

function MonitorStatistcs() {
  const dispatch = useDispatch();
  const [statics, setStatiscs] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [datesArray, setDatesArray] = useState([]);
  const [implementedArray, setImplementedArray] = useState([]);
  const [dayesNames, setDayesNames] = useState([]);
  const [dayesVlaue, setDayesVlaue] = useState([]);
  const [dataCityName, setDataCityName] = useState([]);
  const [dataCityValues, setDataCityValues] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  const [filterDateValue, setFilterDateValue] = useState({
    date1: "",
    date2: "",
  });
  useEffect(() => {
    dispatch(getStatics({date1: filterDateValue.date1, date2: filterDateValue.date2}))
      .unwrap()
      .then((statics) => {
        console.log(statics.data);
        if (statics.code == 200) {
          setStatiscs(statics.data);
          setDatesArray(
            statics.data.implementing_details.map((item) => item.team)
          );
          setImplementedArray(
            statics.data.implementing_details.map((item) => item.building_count)
          );
          setDayesNames(Object.keys(statics.data.implemented_last_week));
          setDayesVlaue(Object.values(statics.data.implemented_last_week));
          setDataCityName(
            statics.data.implemented_building_by_city.map(
              (item) => item.name_ar
            )
          );
          setDataCityValues(
            statics.data.implemented_building_by_city.map(
              (item) => item.building_numbers
            )
          );
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        console.error(err);
        setLoading(false);
        setIsLoading(false);
      });
  }, [dispatch, filterVal]);

  const handelFilter = (e) => {
    // console.log(e.target.value)
    setLoading(true);
    setFilterVal(e.target.value);
    // getTheStatistics();
  };

  const handelDateChange = (e) => {
    const { name, value } = e.target;
    setFilterDateValue((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const filterByDate = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(getStatics({date1: filterDateValue.date1, date2: filterDateValue.date2}))
      .unwrap()
      .then((statics) => {
        console.log(statics.data);
        if (statics.code == 200) {
          setStatiscs(statics.data);
          setDatesArray(
            statics.data.implementing_details.map((item) => item.team)
          );
          setImplementedArray(
            statics.data.implementing_details.map((item) => item.building_count)
          );
          setDayesNames(Object.keys(statics.data.implemented_last_week));
          setDayesVlaue(Object.values(statics.data.implemented_last_week));
          setDataCityName(
            statics.data.implemented_building_by_city.map(
              (item) => item.name_ar
            )
          );
          setDataCityValues(
            statics.data.implemented_building_by_city.map(
              (item) => item.building_numbers
            )
          );
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        console.error(err);
        setLoading(false);
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading open={true} />;
  return (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="statics_wrapper">
        <div className="statics_cards">
          <Container maxWidth="xxl">
            <Grid container spacing={2}>
              <Grid sm={12} xs={12} md={12}>
                <div className="statics_filter">
                  <h5>تصفية</h5>

                  <form action="" onSubmit={filterByDate}>
                    <div className="form_group">
                      <label htmlFor="">من</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={handelDateChange}
                        value={filterDateValue.date1}
                        name="date1"
                      />
                    </div>

                    <div className="form_group">
                      <label htmlFor="">الى</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={handelDateChange}
                        value={filterDateValue.date2}
                        name="date2"
                      />
                    </div>

                    <div className="form_group">
                      <label htmlFor="" style={{ color: "#fff" }}>
                        .
                      </label>
                      <button className="btn_style">تصفيه</button>
                    </div>
                  </form>
                </div>
              </Grid>

              <Grid sm={12} xs={12} md={4}>
                <div className="statics_card">
                  <h5>جميع المناطق</h5>
                  <p className="number">{statics.districts?.total}</p>
                  <CircularChart statics={statics.districts} label="مناطق" />
                </div>
              </Grid>

              <Grid sm={12} xs={12} md={4}>
                <div className="statics_card">
                  <h5>جميع المباني</h5>
                  <p className="number">{statics.buildings?.total}</p>
                  <CircularChartBuilding
                    statics={statics.buildings}
                    label="مباني"
                  />
                </div>
              </Grid>

              <Grid sm={12} xs={12} md={4}>
              <div className="box_style_bg list_reports_bg">
                <div className="box_header day_box">
                  <h5>الموظفين</h5>
                </div>
                <ul className="reports_list">
                  <li>
                    {" "}
                    <span>المجموع</span> <span>{statics.employees.total}</span>
                  </li>
                  <li>
                    {" "}
                    <span>مدراء المشاريع</span>{" "}
                    <span>{statics.employees.pm}</span>
                  </li>
                  <li>
                    {" "}
                    <span>رؤساء الاقسام</span>{" "}
                    <span>{statics.employees.dh}</span>
                  </li>
                  <li>
                    {" "}
                    <span>اعضاء الفرق</span> <span>{statics.employees.tm}</span>
                  </li>
                  <li>
                    {" "}
                    <span>الفرق</span> <span>{statics.employees.team}</span>
                  </li>
                </ul>
              </div>
            </Grid>

              {/* <Grid sm={12} xs={12} md={4}>
                <div className="statics_card">
                  <h5>جميع الاقسام</h5>
                  <p className="number">{statics.employees.dh}</p>
                </div>
              </Grid> */}
            </Grid>



          </Container>
        </div>

        <Container maxWidth="xxl">
          <Grid container spacing={2} className="algin-center">
            {/* <Grid xs={12} md={6}>
              <div className="box_style_bg">
                <div className="box_header day_box">
                  <h5>تفاصيل التركيب</h5>
                </div>
                <BarChart
                  height={200}
                  datesArray={datesArray}
                  implementedArray={implementedArray}
                />
              </div>
            </Grid> */}

           

            <Grid xs={12} md={12}>
              <div className="box_style_bg">
                <div className="box_header day_box">
                  <h5>تفاصيل تركيب اخر اسبوع</h5>
                </div>
                <BarChart
                  height={150}
                  datesArray={dayesNames}
                  implementedArray={dayesVlaue}
                />
              </div>
            </Grid>
            <Grid xs={12} md={12}>
              <div className="box_style_bg">
                <div className="box_header day_box">
                  <h5>تفاصيل تركيب اخر اسبوع</h5>
                </div>
                <BarChart
                  height={150}
                  datesArray={dataCityName}
                  implementedArray={dataCityValues}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default MonitorStatistcs;
