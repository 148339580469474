import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoryDialog({
  historiews,
  openDialog,
  setOpenDialog,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="history_dialog"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"العمليات السابقه"}</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-slide-description">
            {historiews.length == 0 ? (
              <p className="notfoundhistory">لا توجد عمليات سابقه</p>
            ) : (
              <ul>
                {historiews.map((his) => (
                  <li>
                    <p>{his.comment}</p>
                    <span className="date">{his?.created_at?.split("T")[0]}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
