import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import DataGridDemo from "../../components/DataTable";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getListUserDetials,
  getListUserDetialsTeam,
} from "../../store/superadmin/usersSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function Listdetails() {
  const params = useParams();
  const type = params.type;
  // console.log(type);
  const [loading, setLoading] = useState(true);
  const [listdetaisl, setListDetails] = useState([]);
  const [coulmns, setCoulmns] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.type == "forpm") {
      setCoulmns([
        { field: "id", headerName: "الرقم التعريفي", width: 100 },
        { field: "name", headerName: "الاسم", editable: true, width: 150 },
        {
          field: "districts_assigned",
          headerName: "عدد المناطق المكلف بها",
          editable: true,
          width: 150,
        },
        {
          field: "team_count",
          headerName: "عدد الفرق المشرف عليه",
          editable: true,
          width: 150,
        },
        {
          field: "buildings_assigned",
          headerName: "اجمالي اللوحات المكلف بها",
          editable: true,
          width: 190,
        },
        {
          field: "implemented_buildings",
          headerName: "لوحات تم تركيبها",
          editable: true,
          width: 150,
        },
        {
          field: "unImplemented_buildings",
          headerName: "لوحات قيد الإنتظار",
          editable: true,
          width: 150,
        },
        // {
        //   field: "implementation_period",
        //   headerName: "اختيار الفتره",
        //   editable: false,
        //   width: 150,
        //   renderCell: (params) => (
        //     <div className="">
        //       <span>{params.row?.dh_distributions[0]?.implementation_period}</span>
        //     </div>
        //   ),
        // },
      ]);

      dispatch(getListUserDetials())
        .unwrap()
        .then((data) => {
          console.log(data);
          setLoading(false);
          setListDetails(data);
        }).catch((error) => {
          toast.error(error.message);
          setLoading(false);
        });
    } else if (params.type == "fordh") {
      setCoulmns([
        { field: "id", headerName: "الرقم التعريفي", width: 100 },
        {
          field: "name",
          headerName: "اسم المشرف",
          width: 150,
          renderCell: (params) => (
            <div className="">
              <span>{params.row.supervisor.name}</span>
            </div>
          ),
        },
        // {
        //   field: "districts_assigned",
        //   headerName: "عدد المناطق المكلف بها",
        //   editable: true,
        //   width: 150,
        // },
        {
          field: "role_name_ar",
          headerName: "اسم الفني",
          width: 150,
          renderCell: (params) => (
            <div className="">
              <span>{params.row.technician.name}</span>
            </div>
          ),
        },
        {
          field: "building_count",
          headerName: "اجمالي اللوحات المكلف بها",
          editable: false,
          width: 190,
        },
        {
          field: "implemented_buildings",
          headerName: "لوحات تم تركيبها",
          editable: false,
          width: 150,
        },
        {
          field: "unImplemented_buildings",
          headerName: "لوحات قيد الإنتظار",
          editable: false,
          width: 150,
        },
        {
          field: "implementation_period",
          headerName: "اختيار الفتره",
          editable: false,
          width: 150,
          renderCell: (params) => (
            <div className="">
              <span>{params.row?.dh_distributions[0]?.implementation_period}</span>
            </div>
          ),
        },
      ]);

      dispatch(getListUserDetialsTeam())
        .unwrap()
        .then((data) => {
          // console.log(data);
          setLoading(false);
          setListDetails(data);
        }).catch((error) => {
          toast.error(error.message);
          setLoading(false);
        });
    }
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <div className="manage_employee plates_list">
            <Grid container spacing={8} className="algin-center">
              <Grid sm={12} xs={12} md={11}>
                <div className="table_show table_style">
                  <h5>قائمة المناطق</h5>
                  <DataGridDemo coulmns={coulmns} rows={listdetaisl} />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      ;
    </>
  );
}

export default Listdetails;
