import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AddNewEmployee from "./routes/AddNewEmployee";
import EmployeeManage from "./routes/EmployeeManage";
import TeamManagment from "./routes/TeamManagment";
import AddNewTeam from "./routes/AddNewTeam";
import PlatesList from "./routes/PlatesList";
import AddNewPlates from "./routes/AddNewPlates";
import AddMultiPlates from "./routes/AddMultiPlates";
import PlatesListConfirm from "./routes/PlatesListConfirm";
import SuperStatics from "./routes/superadmin/SuperStatics";
import SuperAdmin from "./routes/superadmin/SuperAdmin";
import ListUsers from "./routes/users/ListUsers";
import AreaList from "./routes/Areas/AreaList";
import AssignAreas from "./routes/Areas/AssignAreas";
import { Provider } from "react-redux";
import store from "./store";
import EditUser from "./routes/users/EditUser";
import AddNewArea from "./routes/Areas/AddNewArea";
import EditArea from './routes/Areas/EditArea';
import DistrictsList from './routes/ditricts/DistrictsList';
import AddDistrictKMl from "./routes/ditricts/AddDistrictKMl";
import AddNewDistrict from "./routes/ditricts/AddNewDistrict";
import AssignDistrict from "./routes/ditricts/AssignDistrict";
import BuilsingList from "./routes/buildings/BuilsingList";
import AddBulkBuilding from "./routes/buildings/AddBulkBuilding";
import AddNewUser from './routes/users/AddNewUser';
import ProjectManager from "./routes/pm/ProjectManager";
import PmStatics from "./routes/pm/PmStatics";
import DepartmentHead from "./routes/dh/DepartmentHead";
import DhStatics from "./routes/dh/DhStatics";
import DhAssignDistrictsToTeam from "./routes/ditricts/DhAssignDistrictsToTeam";
import TeamMember from "./routes/tm/TeamMember";
import TeamStatics from "./routes/tm/TeamStatics";
import AllMember from "./routes/AllMember";
import AllTeams from "./routes/AllTeams";
import AddNewTeamFromDh from "./routes/AddNewTeamFromDh";
import Profile from "./routes/Profile";
import ResetPaswword from "./routes/ResetPaswword";
import EditTeam from "./routes/EditTeam";
import NotFound from "./routes/NotFound";
import AllPins from "./routes/buildings/AllPins";
import AllFilter from "./routes/AllFilter";
import EditDistrict from "./routes/ditricts/EditDistrict";
import Listdetails from "./routes/users/Listdetails";
import MapPolygan from "./routes/ditricts/MapPolygan";
import EditDistrictDh from "./routes/ditricts/EditDistrictDh";
import DistrictPolygan from "./routes/ditricts/DistrictPolygan";
import EditBuildingStatus from "./routes/buildings/EditBuildingStatus";
import Monitor from "./routes/monitor/Monitor";
import MonitorStatistcs from "./routes/monitor/MonitorStatistcs";
import Notifications from "./routes/Notifications";
import AllPolygan from "./routes/ditricts/AllPolygan";


const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "superadmin",
    element: <SuperAdmin />,
    children: [
      { index: true, element: <SuperStatics /> },
      { path: "add-new-user/:type", element: <AddNewUser /> },
      { path: "edit-user/:type/:id", element: <EditUser /> },
      { path: "all-users", element: <ListUsers /> },
      // { path: "superadminnewara", element: <AddNewArea /> },
      { path: "arealist", element: <AreaList /> },
      { path: "assign-area", element: <AssignAreas /> },
      // { path: "edit-area/:id", element: <EditArea /> },
      { path: "district-list", element: <DistrictsList /> },
      { path: "add-ditrict-kml", element: <AddDistrictKMl /> },
      // { path: "add-new-ditrict", element: <AddNewDistrict /> },
      { path: "assign-ditrict", element: <AssignDistrict /> },
      { path: "building-list", element: <BuilsingList/> },
      { path: "add-bulk-buildings", element: <AddBulkBuilding/> },
      { path: "add-new-team", element: <AddNewTeam/> },
      { path: "settings", element: <Profile/> },
      { path: "rest-password", element: <ResetPaswword/> },
      { path: "all-pins", element: <AllPins/> },
      {path: "notifications", element:<Notifications /> },
    ],
  },
  {
    path: "pm",
    element: <ProjectManager />,
    children: [
      { index: true, element: <PmStatics /> },
      { path: "add-new-user/dh", element: <AddNewUser /> },
      { path: "edit-user/:type/:id", element: <EditUser /> },
      { path: "all-users", element: <AllMember /> },
      // { path: "all-users", element: <ListUsers /> },
      // { path: "pmnnewara", element: <AddNewArea /> },
      { path: "arealist", element: <AreaList /> },
      { path: "assign-area", element: <AssignAreas /> },
      { path: "edit-district/:id", element: <EditDistrict /> },
      { path: "district-list", element: <DistrictsList /> },
      { path: "add-ditrict-kml", element: <AddDistrictKMl /> },
      // { path: "add-new-ditrict", element: <AddNewDistrict /> },
      // { path: "assign-ditrict", element: <AssignDistrict /> },
      { path: "building-list", element: <BuilsingList/> },
      { path: "edit-building-status/:buId", element: <EditBuildingStatus/> },
      { path: "add-bulk-buildings", element: <AddBulkBuilding/> },
      // { path: "add-new-team", element: <AddNewTeam/> },
      { path: "settings", element: <Profile/> },
      { path: "rest-password", element: <ResetPaswword/> },
      {path: "allfilter/:type/:id", element:<AllFilter /> },
      {path: "listdetails/:type", element:<Listdetails /> },
      {path: "district-polygan/:disId", element:<DistrictPolygan /> },
      { path: "all-pins", element: <AllPins/> },
      {path: "notifications", element:<Notifications /> },
    ]
  },
  {
    path: "dh",
    element: <DepartmentHead />,
    children: [
      { index: true, element: <DhStatics /> },
      { path: "add-new-user/tm", element: <AddNewUser /> },
      { path: "all-users", element: <AllMember /> },
      { path: "all-teams", element: <AllTeams /> },
      { path: "edit-team/:id", element: <EditTeam/> },
      { path: "edit-user/:type/:id", element: <EditUser /> },
      // { path: "all-users", element: <ListUsers /> },
      // { path: "pmnnewara", element: <AddNewArea /> },
      // { path: "arealist", element: <AreaList /> },
      // { path: "assign-area", element: <AssignAreas /> },
      { path: "edit-district/:id", element: <EditDistrictDh /> },
      // { path: "edit-area/:id", element: <EditArea /> },
      { path: "district-list", element: <DistrictsList /> },
      // { path: "add-ditrict-kml", element: <AddDistrictKMl /> },
      // { path: "add-new-ditrict", element: <AddNewDistrict /> },
      { path: "assign-ditrict-to-team", element: <DhAssignDistrictsToTeam /> },
      { path: "building-list", element: <BuilsingList/> },
      // { path: "add-bulk-buildings", element: <AddBulkBuilding/> },
      { path: "add-new-team", element: <AddNewTeamFromDh/> },
      { path: "settings", element: <Profile/> },
      { path: "rest-password", element: <ResetPaswword/> },
      {path: "allfilter/:type/:id", element:<AllFilter /> },
      {path: "listdetails/:type", element:<Listdetails /> },
      {path: "notifications", element:<Notifications /> },

    ]
  },
  {
    path: "tm",
    element: <TeamMember />,
    children: [
      { index: true, element: <TeamStatics /> },
      // { path: "add-new-user/:type", element: <AddNewUser /> },
      // { path: "edit-user/:type/:id", element: <EditUser /> },
      // { path: "all-users", element: <ListUsers /> },
      // { path: "pmnnewara", element: <AddNewArea /> },
      // { path: "arealist", element: <AreaList /> },
      // { path: "assign-area", element: <AssignAreas /> },
      // { path: "edit-area/:id", element: <EditArea /> },
      // { path: "district-list", element: <DistrictsList /> },
      // { path: "add-ditrict-kml", element: <AddDistrictKMl /> },
      // { path: "add-new-ditrict", element: <AddNewDistrict /> },
      // { path: "assign-ditrict-to-team", element: <DhAssignDistrictsToTeam /> },
      { path: "building-list", element: <BuilsingList/> },
      // { path: "add-bulk-buildings", element: <AddBulkBuilding/> },
      // { path: "add-new-team", element: <DhAddnewTeam/> },
      { path: "settings", element: <Profile/> },
      { path: "rest-password", element: <ResetPaswword/> },
      { path: "map-polygan", element: <MapPolygan/> },
      {path: "notifications", element:<Notifications /> },
    ]
  },

  {
    path: "monitor",
    element: <Monitor />,
    children: [
      { index: true, element: <MonitorStatistcs /> },
      // { path: "add-new-user/:type", element: <AddNewUser /> },
      // { path: "edit-user/:type/:id", element: <EditUser /> },
      // { path: "all-users", element: <ListUsers /> },
      // { path: "pmnnewara", element: <AddNewArea /> },
      // { path: "arealist", element: <AreaList /> },
      // { path: "assign-area", element: <AssignAreas /> },
      // { path: "edit-area/:id", element: <EditArea /> },
      { path: "district-list", element: <DistrictsList /> },
      // { path: "add-ditrict-kml", element: <AddDistrictKMl /> },
      // { path: "add-new-ditrict", element: <AddNewDistrict /> },
      // { path: "assign-ditrict-to-team", element: <DhAssignDistrictsToTeam /> },
      { path: "building-list", element: <BuilsingList/> },
      // { path: "add-bulk-buildings", element: <AddBulkBuilding/> },
      // { path: "add-new-team", element: <DhAddnewTeam/> },
      { path: "settings", element: <Profile/> },
      { path: "rest-password", element: <ResetPaswword/> },
      // { path: "map-polygan", element: <MapPolygan/> },
      {path: "district-polygan/:disId", element:<DistrictPolygan /> },
      {path: "allpolygans", element:<AllPolygan /> },
      {path: "notifications", element:<Notifications /> },
    ]
  },

  {
    path: "*",
    element: <NotFound />, 
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={store}>
    <RouterProvider router={routes} />
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
