import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, editArea, getAreaById } from "../../store/areasSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

function EditArea() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const singlearea = useSelector((state) => state.areasSlice.singlearea);
  const [loading, setLoding] = useState(true);
  const areaId = params.id;
  const [inputs, setInputs] = useState({
    name: "",
    code: "",
  });
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  }else if (role_id == "3") {
    roleName = "dh";
  }




  useEffect(() => {
    if (areaId) {
      dispatch(getAreaById(areaId))
        .unwrap()
        .then((data) => {
          setInputs(data);
          setLoding(false);
        }).catch((error) => {
          toast.error(error.message);
          setLoding(false);
        });
    }
  }, [dispatch]);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoding(true);
      dispatch(editArea(inputs))
        .unwrap()
        .then((data) => {
          setLoding(false);
          toast.success(`تم التعديل بنجاح`);
          navigate(`/${roleName}/arealist`)
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>تعديل المنطقه</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form onSubmit={saveData} action="">
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">كود المنطقه:</label>
                  <input
                    type="text"
                    name="code"
                    disabled={true}
                    value={inputs.code}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال رقم المنطقه الجديدة"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("code")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "code",
                    singlearea.code,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">اسم المنطقه:</label>
                  <input
                    type="text"
                    name="name"
                    value={inputs.name}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال اسم المنطقه الجديدة"
                  />
                </div>
              </div>

              <div className="btn_submit">
                <button className="btn_style">اضافة</button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default EditArea;
