import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  Marker,
  MarkerF,
  Polygon,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MapDialogforImplemented({
  mapPointsImp,
  openMapoDialogImp,
  setOpenMapDialogImp,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenMapDialogImp(false);
  };

  const isValidCoordinate = (coordinate) => {
    return !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
  };


  const implementingCenter = {
    lat: isValidCoordinate(mapPointsImp.implementing_lat) ? parseFloat(mapPointsImp.implementing_lat): 0,
    lng: isValidCoordinate(mapPointsImp.implementing_lang) ? parseFloat(mapPointsImp.implementing_lang): 0
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };


  return (
    <div>
      <Dialog
        open={openMapoDialogImp}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="map_dialog"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"موقع التركيب"}</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-slide-description">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={implementingCenter}
              zoom={17}
              options={{
                // styles: exampleMapStyles,
                streetViewControl: false,
              }}
            >
              

              <Marker
                position={implementingCenter}
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
              />
              
            </GoogleMap>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
