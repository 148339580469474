import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, NavLink, useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getProfile } from "../store/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
import { userLogout } from "../store/superadmin/usersSlice";
import { toast } from "react-toastify";

function MonitorSideNav() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const usersprofile = useSelector(
    (state) => state.userProfileSlice.usersprofile
  );
  const [loading, setLoading] = useState(true);
  const signOut = () => {
    setLoading(true);
    dispatch(userLogout()).then((data) => {
      localStorage.setItem("role_id", "");
      localStorage.setItem("token", "");
      toast.success("تم تسجيل الخروج بنجاح");
      setLoading(false);
      navigate("/");
    });
  };

  useEffect(() => {
    dispatch(getProfile())
      .unwrap()
      .then((data) => {
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      <div className="side-nav">
        {loading ? (
          <Loading open="true" />
        ) : (
          <div className="person-info">
            <div className="card-img">
              <div className="img-parent">
                <img
                  src={`${process.env.PUBLIC_URL}${usersprofile.profile_image}`}
                  alt=""
                />
              </div>
            </div>
            <div className="card-body">
              <h5>{usersprofile.name}</h5>
              <p>{usersprofile.role_name_ar}</p>
            </div>
          </div>
        )}

        <div className="side_links">
          <ul>
            <li>
              <NavLink to="/monitor" end>
                <span className="icon-home-icon"></span>
                <span>الصفحة الرئيسية</span>
              </NavLink>
            </li>

            <li>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-paint2"></span>
                    <span>اداره المناطق</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/monitor/district-list">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع المناطق
                    </NavLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-paint2"></span>
                    <span>اداره اللوحات</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/monitor/building-list">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع اللوحات
                    </NavLink>
                    {/* <NavLink to="/monitor/all-pins">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع النقاط
                    </NavLink> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            {/* <li>
            <Link to="/pm/add-new-team">
              <span className="icon-reports"></span>
              <span>اضافه فريق</span>
            </Link>
          </li> */}
            <li>
              <NavLink to="/monitor/settings">
                <span className="icon-settings"></span>
                <span>الاعدادات</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/monitor/rest-password">
                <span>
                  <i className="fas fa-lock"></i>
                </span>
                <span>تغيير كلمه السر</span>
              </NavLink>
            </li>
            <li className="signout_btn">
              <a onClick={signOut}>
                <span>
                  <LogoutOutlinedIcon></LogoutOutlinedIcon>
                </span>
                <span>تسجيل الخروج</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default MonitorSideNav;
