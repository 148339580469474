import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  assignArea,
  deleteArea,
  getAreas,
  getUnAssignedAreas,
} from "../../store/areasSlice";
import TablePaginaiton from "../../components/TablePaginaiton";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import { getDhUsers } from "../../store/usersLists";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import { getUnAssignedDistricts } from "../../store/districtsSlice";

function AssignAreas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const areas = useSelector((state) => state.areasSlice.areas);
  const unassignedareas = useSelector(
    (state) => state.areasSlice.unassignedareas
  );
  const unassignedDistricts = useSelector(
    (state) => state.ditrictsSlice.unassignedDistricts
  );
  const totlepages = useSelector(
    (state) => state.areasSlice.unassignedareaspage
  );
  const isLoading = useSelector((state) => state.ditrictsSlice.isLoading);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [loading, setLoading] = useState();
  const [departmentheadId, setDepartmentheadId] = useState("");
  const [impelmentPeriod, setImpelmentPeriod] = useState();
  const [page, setPage] = useState(1);

  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  }

  const selectionRows = true;

  useEffect(() => {
    // dispatch(getUnAssignedAreas());
    dispatch(getUnAssignedDistricts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDhUsers());
  }, [dispatch]);

  const dhUsers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  const handlepageChange = (event, value) => {
    setPage(value);
    dispatch(getAreas(value));
  };

  const handelInputChange = (event) => {
    setDepartmentheadId(event.target.value);
  };

  const handelInputperiodChange = (event) => {
    setImpelmentPeriod(event.target.value);
  };

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const areaData = {
        dh_id: departmentheadId,
        area_id: rowSelectionModel,
        implementation_period: parseInt(impelmentPeriod),
      };
      console.log(areaData);
      dispatch(assignArea(areaData))
        .unwrap()
        .then((data) => {
          setLoading(false);
          toast.success("تم التوزيع بنجاح");
          navigate(`/${roleName}/district-list`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const coulmns = [
    { field: "id", headerName: "ID", width: 110 },
    { field: "code", headerName: "الرمز البريدي", editable: false, width: 150 },
    {
      field: "buildings_count",
      headerName: "عدد اللوحات",
      editable: false,
      width: 180,
    },
  ];

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="manage_employee plates_list">
          <Grid container spacing={8} className="algin-center">
            <Grid sm={12} xs={12} md={11}>
              <div className="table_show table_style">
                <h5>توزيع منطقه على رئيس القسم</h5>
                <a href="" className="showall">
                  عرض الكل
                </a>
                <form action="" onSubmit={saveData}>
                  <div className="form-group">
                    <label htmlFor="">اختر رئيس القسم</label>
                    <select
                      name="departmentheadId"
                      onChange={handelInputChange}
                      id=""
                      className="form-control"
                      onBlur={() =>
                        simpleValidator.current.showMessageFor(
                          "departmentheadId"
                        )
                      }
                    >
                      <option defaultValue="0" disabled>
                        يرجى اختيار المنصب
                      </option>
                      {dhUsers.map((dhus) => (
                        <option key={dhus.id} value={dhus.id}>
                          {dhus.name}
                        </option>
                      ))}
                    </select>
                    <div className="error" style={{ marginRight: 0 }}>
                      {simpleValidator.current.message(
                        "departmentheadId",
                        departmentheadId,
                        "required"
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor=""
                      style={{ display: "block", width: "fit-content" }}
                    >
                      عدد الاسابيع المحتمله
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      name="impelmentPeriod"
                      onChange={handelInputperiodChange}
                      id=""
                      value={impelmentPeriod}
                      onBlur={() =>
                        simpleValidator.current.showMessageFor(
                          "impelmentPeriod"
                        )
                      }
                    />
                    <div className="error" style={{ marginRight: 0 }}>
                      {simpleValidator.current.message(
                        "impelmentPeriod",
                        impelmentPeriod,
                        "required"
                      )}
                    </div>
                  </div>

                  <h5 className="titlefor_table">اختر المناطق</h5>
                  <DataGridDemo
                    coulmns={coulmns}
                    selectionRows={selectionRows}
                    rows={unassignedDistricts}
                    handleRowSelection={handleRowSelection}
                  />

                  {/* <TablePaginaiton
                  totlepages={totlepages}
                  page={page}
                  setPage={setPage}
                  handlepageChange={handlepageChange}
                /> */}

                  <div className="btn_submit">
                    <button className="btn_style" type="submit">
                      توزيع
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
            <Grid xs={3}>
              {/* <div className="options_buttons">
              <ul className="list-unstyled">
                <li>
                  <a href="">المناطق الموزعه</a>
                </li>
                <li>
                  <a href="">المناطق الغير موزعه</a>
                </li>
              </ul>
            </div> */}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default AssignAreas;
