import { configureStore } from "@reduxjs/toolkit";
import usersSlice from "./superadmin/usersSlice";
import usersListSlice, {dhUsersReducer} from "./usersLists";
import areasSlice from "./areasSlice";
import ditrictsSlice from './districtsSlice'
import teamSlice from './teamSlice';
import buildingsSlice from './buildingSlice';
import authSlice from './authSlice';
import staticsSlice from './staticsSlice'
import userProfileSlice from './profileSlice';
import notificationsSlice from './notificationSlice'
import historySlice from './historySlice';
import pinsSlice from './pinsSlice';
import allfilterSlice from './allfilterSlice';
import allCities from './citiesSlice';
export default configureStore({
  reducer: {
    usersSlice,
    usersListSlice,
    dhUsersReducer,
    areasSlice,
    ditrictsSlice,
    teamSlice,
    buildingsSlice,
    authSlice,
    staticsSlice,
    userProfileSlice,
    notificationsSlice,
    historySlice,
    pinsSlice,
    allfilterSlice,
    allCities,
  },
});