import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getPins } from "../../store/pinsSlice";

function AllPins() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const pins = useSelector((state) => state.pinsSlice.pins);

  useEffect(() => {
    dispatch(getPins())
      .unwrap()
      .then((data) => {
        setLoading(false);
        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
      });
  }, [dispatch]);

  const coulmns = [
    { field: "id", headerName: "الرقم التعريفي", width: 100 },
    { field: "longitude", headerName: "الطول", editable: true, width: 200 },
    { field: "latitude", headerName: "العرض", editable: true, width: 200 },
    {
      field: "supervisorName",
      headerName: "المشرف",
      editable: true,
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row?.supervisor_name}
        </div>
      ),
    },
    {
      field: "post_code",
      headerName: "الرمز البريدي",
      editable: true,
      width: 200,
    },
    {
      field: "building_code",
      headerName: "كود المبنى",
      editable: true,
      width: 150,
    },
    {
      field: "created_at",
      headerName: "تاريخ التسجيل",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row.created_at.split("T")[0]}
        </div>
      ),
    },
  ];
  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <div className="manage_employee plates_list">
            <Grid container spacing={8} className="algin-center">
              <Grid sm={12} xs={12} md={11}>
                <div className="table_show table_style">
                  <h5>قائمة اللوحات</h5>

                  <DataGridDemo coulmns={coulmns} rows={pins} />

                  {/* <TablePaginaiton
              totlepages={totlepages}
              page={page}
              setPage={setPage}
              handlepageChange={handlepageChange}
            /> */}
                </div>
              </Grid>
              <Grid xs={3}>
                {/* <div className="options_buttons">
            <ul className="list-unstyled">
              <li>
                <a href="">الاحياء الموزعه</a>
              </li>
              <li>
                <a href="">الاحياء الغير موزعه</a>
              </li>
            </ul>
          </div> */}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

export default AllPins;
