import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
}

export const getUsers = createAsyncThunk(
  "users/fetshusers",
  async ({ pageNumber, filterRolId, pmId, dhID }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // console.log(pmId);

      const res = await axios.get(
        `${endpoint}/api/${roleName}/user?role_id=${filterRolId}&page=${pageNumber}&pm_id=${pmId}&dh_id=${dhID}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUsersById = createAsyncThunk(
  "users/fetshusersbyid",
  async (userId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/user/${userId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editUser = createAsyncThunk(
  "users/edituser",
  async ({ userdata, userId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // console.log(userId);
      const res = await axios.post(
        `${endpoint}/api/${roleName}/user/${userId}`,
        userdata,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addUsers = createAsyncThunk(
  "users/addhusers",
  async (userdata, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type":
          "multipart/form-data; boundary=<calculated when request is sent>",
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/user`,
        userdata,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUsers = createAsyncThunk(
  "users/removeusers",
  async (userid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(
        `${endpoint}/api/${roleName}/user/${userid}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return userid;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

export const changeUserPass = createAsyncThunk(
  "users/cahngeuserpass",
  async (useId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // console.log(useId)
      const res = await axios.get(
        `${endpoint}/api/${roleName}/user/password/reset/${useId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

export const userLogout = createAsyncThunk(
  "users/userlogout",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/logout`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

export const getListUserDetials = createAsyncThunk(
  "users/userdetaisl",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

export const getListUserDetialsTeam = createAsyncThunk(
  "users/userdetaislTeam",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/team`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: true,
    users: [],
    singleuser: {},
    pagesnumber: "",
    newpassword: "",
    userlistdetails: [],
    userlistdetailsteam:[],
  },
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.users = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // add user
    [addUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users.push(action.payload);
    },
    [addUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // removeUsers
    [removeUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removeUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    [removeUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getUsersById
    [getUsersById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUsersById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleuser = action.payload;
    },
    [getUsersById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getUsersById
    [editUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
      // state.singleuser = action.payload;
    },
    [editUser.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // changeUserPass
    [changeUserPass.pending]: (state, action) => {
      state.isLoading = true;
    },
    [changeUserPass.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
      state.newpassword = action.payload;
    },
    [changeUserPass.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // userLogout
    [userLogout.pending]: (state, action) => {
      state.isLoading = true;
    },
    [userLogout.fulfilled]: (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
    },
    [userLogout.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getListUserDetials
    [getListUserDetials.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getListUserDetials.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userlistdetails = action.payload;
      // console.log(action.payload);
    },
    [getListUserDetials.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getListUserDetialsTeam
    [getListUserDetialsTeam.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getListUserDetialsTeam.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userlistdetailsteam = action.payload;
      // console.log(action.payload);
    },
    [getListUserDetialsTeam.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default usersSlice.reducer;
