import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../components/DataTable";
import ReturnBtn from "../components/BtnReturn";

function PlatesListConfirm() {
  return (
    <>
      <div className="manage_employee">
        <ReturnBtn />
        <Grid container spacing={8} className="algin-center">
          <Grid xs={9}>
            <div className="table_show table_style">
              <h5>قائمة اللوحات الجديدة</h5>
              <a href="" className="showall">
                عرض الكل
              </a>
              <DataGridDemo />

              <div className="btn_options">
                <button className="btn_style">الغاء</button>
                <button className="btn_style">اضافة</button>
              </div>
            </div>
          </Grid>
          <Grid xs={3}></Grid>
        </Grid>
      </div>
    </>
  );
}

export default PlatesListConfirm;
