import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import { getDhUsers } from "../store/usersLists";
import SimpleReactValidator from "simple-react-validator";
import {
  addTeam,
  getSuperVisorByDh,
  getTeamByDh,
  gettechnicalByDh,
  getunassignedsupervisors,
  getunassignedtechnicals,
} from "../store/teamSlice";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddNewTeamFromDh() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const dhusers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const unassignedsupervisors = useSelector((state) => state.teamSlice.unassignedsupervisors);
  const unassignedtechnicals = useSelector((state) => state.teamSlice.unassignedtechnicals);
  const [loading, setLoding] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [inputs, setInputs] = useState({
    name: "",
    technician_id: "",
    supervisor_id: "",
  });


  useEffect(() => {
    dispatch(getunassignedsupervisors())
      .unwrap()
      .then((data) => {
        setLoding(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoding(false);
      });

      dispatch(getunassignedtechnicals())
      .unwrap()
      .then((data) => {
        setLoding(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoding(false);
      });
  }, [dispatch]);


  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    e.preventDefault();
    
    if (simpleValidator.current.allValid()) {
      setLoding(true);
      // console.log(inputs);
      dispatch(addTeam(inputs))
      .unwrap()
      .then((data) => {
        toast.success(`تم الاضافه بنجاح`)
        setTimeout(() => {
          setLoding(false);
          navigate("/dh/all-users")
        }, 1000);
      }).catch((error) => {
        toast.error(error.message);
        setLoding(false);
      })
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>إضافة فريق جديد</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form action="" onSubmit={saveData}>
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">اسم الفريق:</label>
                  <input
                    type="text"
                    name="name"
                    value={inputs.name}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="يرجي ادخال اسم الفريق"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("name")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "name",
                    inputs.name,
                    "required"
                  )}
                </div>
              </div>

            
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">اسم المشرف:</label>
                  <select
                    name="supervisor_id"
                    value={inputs.supervisor_id}
                    onChange={handelInputChange}
                    id=""
                    className="form-control"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("supervisor_id")
                    }
                  >
                    <option value="" selected disabled>
                      يرجي اختيار اسم المشرف
                    </option>
                    {unassignedsupervisors.map((sp) => (
                      <option value={sp.id} key={sp.id}>
                        {sp.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "supervisor_id",
                    inputs.supervisor_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">اسم الفني:</label>
                  <select
                    name="technician_id"
                    value={inputs.technician_id}
                    onChange={handelInputChange}
                    id=""
                    className="form-control"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("technician_id")
                    }
                  >
                    <option value="" selected disabled>
                      يرجي اختيار اسم الفني
                    </option>
                    {unassignedtechnicals.map((teh) => (
                      <option value={teh.id} key={teh.id}>
                        {teh.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "technician_id",
                    inputs.technician_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="btn_submit">
                <button className="btn_style">اضافة</button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default AddNewTeamFromDh;
