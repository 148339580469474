// import styles from "../styles/Home.module.css";
import React, { useEffect, useState, useMemo } from "react";
import Loading from "../../components/Loading";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerF,
  Polygon,
  InfoWindow,
  OverlayView,
  MarkerClusterer,
} from "@react-google-maps/api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getThePolyganandBuildings } from "../../store/districtsSlice";
import { toast } from "react-toastify";

const containerStyle = {
  width: "100%",
  height: "900px",
};

const center = {
  lat: 26.3351,
  lng: 17.2283,
};

const exampleMapStyles = [
  {
    featureType: "all",
    elementType: "all",
  },
];

// ... (previous imports and constants)

function AllPolygan() {
  const [activeMarker, setActiveMarker] = useState(null);
  const dispatch = useDispatch();
  const [coordinatesArray, setCoordinatesArray] = useState([]);
  const [implemunimplemArray, setImplemunimplemArray] = useState([]);
  const [polygan, setPolygan] = useState([]);
  const [loading, setLoading] = useState(true);

  // get data
  const [points, setPoints] = useState([]);

  useEffect(() => {
    dispatch(getThePolyganandBuildings())
      .unwrap()
      .then((data) => {
        setLoading(false);
        const allCoordinatesArray = [];
        const allPolygan = [];
        // console.log(data)
        // Iterate through the data array
        data.forEach((object, index) => {
          // const newcoordinatesArray = object.building.map((item) => ({
          //   id: item.id,
          //   lat: item.latitude,
          //   lng: item.longitude,
          //   status: item.status,
          //   building_code: item.building_code,
          //   info: item.status === "3" ? "تم التركيب" : "قيد الانتظار",
          // }));


          function splitNumber(number) {
            const str = number.toString();
            const firstPart = str.substring(0, 2);
            const secondPart = str.substring(2, 4);
          
            return [parseInt(firstPart, 10), parseInt(secondPart, 10)];
          }
          
          const number = 5043;
          const [firstPart, secondPart] = splitNumber(object?.code);

          const newpolygan = object.polygon?.coordinates.map((coordinates) => ({
            paths: coordinates.map((item) => ({
              lat: item[0],
              lng: item[1],
            })),
            status_flag: object?.status_flag,
            buildings_count: object?.buildings_count,
            formula: `${firstPart}.${secondPart}.${object?.buildings_count}-${object?.implemented_building_count}`
          }));

          // Accumulate the coordinates and polygon coordinates
          // allCoordinatesArray.push(...newcoordinatesArray);
          allPolygan.push(...newpolygan);
        });

        // setCoordinatesArray(allCoordinatesArray);
        setPolygan(allPolygan);
        // console.log(allPolygan);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  }, [dispatch]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB4ge1JoFe8y0OKylT4VEx0w8Q32IPDy6U",
  });

  const handleMarkerClick = (item) => {
    if (item === activeMarker) {
      return;
    }
    setActiveMarker(item);
  };

  const statusColorMap = {
    0: "#808080", // Gray color for statusColor 0
    1: "#0000FF", // Gray color for statusColor 1
    2: "#FFFF00", // Gray color for statusColor 2
    // Add more cases if needed
  };

  const getPolygonCenter = (path) => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => {
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
    });
    return bounds.getCenter().toJSON();
  };

  return isLoaded ? (
    <>
      {loading ? <Loading open={true} /> : null}
      <div className="googleMap_map our_locations" id="ourlocation_section">
        <div className="">
          <div className="about_header">
            <h5 className="text-f-9 text-w-700 text-uppercase text-center">
              Our Locations
            </h5>
          </div>

          <div className="map_canvas_parent">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={6.2}
              options={{
                styles: exampleMapStyles,
                streetViewControl: false,
              }}
            >
              {polygan.map((polygon, index) => (
                <div key={index}>
                  {/* {console.log(polygon)} */}
                  <Polygon
                    key={index}
                    paths={polygon.paths}
                    options={{
                      fillColor: statusColorMap[polygon.status_flag] || null, // Replace this color code with your desired color
                      fillOpacity: 0.4, // Adjust the opacity as needed
                      strokeColor: statusColorMap[polygon.status_flag] || null, // Outline color
                      strokeOpacity: 0.8, // Outline opacity
                      strokeWeight: 2, // Outline thickness
                    }}
                  />
                  <OverlayView
                    position={getPolygonCenter(polygon.paths)}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({
                      x: -width / 2,
                      y: -height / 2,
                    })}
                  >
                    <div
                      style={{
                        background: "#fff",
                        border: "1px solid #ccc",
                        padding: 5,
                        borderRadius: 5,
                        fontSize: 12,
                        color: "#000",
                        width: "max-content",
                        height: "fit-content",
                      }}
                    >
                    {polygon.formula}
                    </div>
                  </OverlayView>
                </div>
              ))}
            </GoogleMap>

            <div className="img_info">
              <img src="/images/infomap_3.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default AllPolygan;
