import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BuidingDetailsDialog({
  openInfoDialog,
  setOpenInfoDialog,
  buildingDetials,
  setBuildingDetails,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setBuildingDetails("");
    setOpenInfoDialog(false);
    // Clear the previous timeout (if it exists)
    // if (closeTimeout) {
    //   clearTimeout(closeTimeout);
    // }

    // var closeTimeout = setTimeout(() => {
    // }, 1000);
  };

  // console.log(buildingDetials);

  return (
    <div>
      <Dialog
        open={openInfoDialog}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="history_dialog"
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            overflowY: "auto", // To prevent content overflow
            maxHeight: "none", // Remove the max height
          },
        }}
      >
        <DialogTitle>{"الصوره"}</DialogTitle>
        <DialogContent>
          <div className="dialog-info" style={{ height: "700px" }}>
            <img src={`${buildingDetials}`} alt="" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
