import React from 'react'
import ReturnBtn from '../components/BtnReturn'

function AddNewEmployee() {
  return (
    <>
      <ReturnBtn />
      <div className="form_style">
        <div className="form_header">
          <h5>إضافة موظف جديد</h5>
          <p>يرجي ادخال البيانات التالية</p>
        </div>
        <form action="">
          <div className="form-group">
            <label htmlFor="">الاسم:</label>
            <input type="text" className="form-control" placeholder="يرجي ادخال اسم الموظف" />
          </div>

          <div className="form-group">
            <label htmlFor="">الوظيفة:</label>
            <select name="" id="" className='form-control'>
              <option value="">يرجي ادخال الوظيفة</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="">الرقم الوطني:</label>
            <input type="text" className="form-control" placeholder="يرجي ادخال الرقم الوطني" />
          </div>


          <div className="form-group">
            <label htmlFor="">الرقم الوظيفي:</label>
            <input type="text" className="form-control" placeholder="يرجي ادخال الرقم الوظيفي" />
          </div>

          <div className="btn_submit">
            <button className="btn_style">اضافة</button>
          </div>
        </form>
      </div>
    </>

  )
}

export default AddNewEmployee